    @import "styles/variables";
    @import "styles/mixins";

.filtersContainer {
    margin-bottom: $spacing-7;
}

.storiesContainer {
    display: grid;
    gap: $spacing-7;
    grid-template-columns: 1fr;

    /* stylelint-disable max-nesting-depth */
    @include tablet-up {
        gap: $spacing-7 $spacing-5;
        grid-template-columns: repeat(2, 1fr);
    }

    @include desktop-up {
        gap: $spacing-7;
        grid-template-columns: repeat(3, 1fr);

        &.stacked {
            grid-template-columns: repeat(6, 1fr);

            > :nth-child(1) {
                grid-column: span 3;
            }

            > :nth-child(2) {
                grid-column: span 3;
            }

            > * {
                grid-column: span 2;
            }
        }
    }
    /* stylelint-enable max-nesting-depth */
}

.noStories {
    padding: $spacing-10 0;
    margin: 0 auto;
    text-align: center;

    @include desktop-up {
        padding: 11rem 0;
    }
}

.noStoriesTitle {
    margin-top: $spacing-4;
    margin-bottom: $spacing-2;
}

.noStoriesSubtitle {
    color: $color-base-500;
    margin: 0;
}

.staggered {
    gap: $spacing-7;

    .card {
        margin-bottom: $spacing-7;
    }
}
