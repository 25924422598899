    @import "styles/variables";
    @import "styles/mixins";

.list {
    grid-template-columns: 1fr !important;
}

.fallbackText {
    @include heading-2;

    padding-top: $spacing-8;
    text-align: center;
    font-weight: 400;
    color: $color-base-500;

    @include desktop-up {
        grid-column: span 3;
    }
}
